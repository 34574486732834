// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SheetCard_containerSheetCard__N5-Pa {
    flex: 1 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3rem;
}

.SheetCard_marginCard__iqj5\\+ {
    display: flex;
    min-width: 100%;
}

.SheetCard_marginCard__iqj5\\+ a {
    width: 100%;
    text-decoration: none;
}

.SheetCard_sheetCard__c938t {
    border: 3px solid var(--color-tangerina);
    box-shadow: inside 1px 1px 10px var(--color-tangerina);
    border-radius: 30px;
    border-top: none;
    width: 100%;
}

.SheetCard_sheetCard__c938t h2 {
    padding: 0.9rem 1.2rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: var(--color-tangerina);
    color: white;
}

.SheetCard_contentCard__TLwJb {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
}

.SheetCard_contentCard__TLwJb div span {
    color: white;
    font-weight: bold;
}

.SheetCard_contentCard__TLwJb div {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

#SheetCard_loading__bb8f6 img {
    max-width: 64px;
}

#SheetCard_loading__bb8f6 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/css/SheetCard.module.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,wCAAwC;IACxC,sDAAsD;IACtD,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,6BAA6B;IAC7B,wCAAwC;IACxC,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".containerSheetCard {\n    flex: 1;\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n    gap: 3rem;\n}\n\n.marginCard {\n    display: flex;\n    min-width: 100%;\n}\n\n.marginCard a {\n    width: 100%;\n    text-decoration: none;\n}\n\n.sheetCard {\n    border: 3px solid var(--color-tangerina);\n    box-shadow: inside 1px 1px 10px var(--color-tangerina);\n    border-radius: 30px;\n    border-top: none;\n    width: 100%;\n}\n\n.sheetCard h2 {\n    padding: 0.9rem 1.2rem;\n    border-top-left-radius: 30px;\n    border-top-right-radius: 30px;\n    background-color: var(--color-tangerina);\n    color: white;\n}\n\n.contentCard {\n    padding: 2rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    color: white;\n}\n\n.contentCard div span {\n    color: white;\n    font-weight: bold;\n}\n\n.contentCard div {\n    display: flex;\n    flex-direction: column;\n    gap: 0.4rem;\n}\n\n#loading img {\n    max-width: 64px;\n}\n\n#loading {\n    width: 100%;\n    height: auto;\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerSheetCard": `SheetCard_containerSheetCard__N5-Pa`,
	"marginCard": `SheetCard_marginCard__iqj5+`,
	"sheetCard": `SheetCard_sheetCard__c938t`,
	"contentCard": `SheetCard_contentCard__TLwJb`,
	"loading": `SheetCard_loading__bb8f6`
};
export default ___CSS_LOADER_EXPORT___;
