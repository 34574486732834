// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"%23f29559\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"6 9 12 15 18 9\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_form_select__d-5fJ select {
    background: transparent;
    padding: 0.7rem 1.5rem;
    border: 1px solid var(--color-tangerina);
    border-radius: 30px;
    color: var(--color-tangerina);
    width: 100%;
}

.Select_form_select__d-5fJ select:focus {
    outline: none;
}

.Select_form_select__d-5fJ {
    width: 100%;
}

.Select_form_select__d-5fJ select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent
        url(${___CSS_LOADER_URL_REPLACEMENT_0___})
        no-repeat right 10px center;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/css/Select.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,wCAAwC;IACxC,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,wBAAwB;IACxB;;mCAE+B;AACnC","sourcesContent":[".form_select select {\n    background: transparent;\n    padding: 0.7rem 1.5rem;\n    border: 1px solid var(--color-tangerina);\n    border-radius: 30px;\n    color: var(--color-tangerina);\n    width: 100%;\n}\n\n.form_select select:focus {\n    outline: none;\n}\n\n.form_select {\n    width: 100%;\n}\n\n.form_select select {\n    appearance: none;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    background: transparent\n        url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"%23f29559\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"6 9 12 15 18 9\"/></svg>')\n        no-repeat right 10px center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_select": `Select_form_select__d-5fJ`
};
export default ___CSS_LOADER_EXPORT___;
