// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.App_App__BPIIb {
    width: 1250px;
    height: 100%;
    width: 100%;
    padding: 2rem 0 0 0;
    flex: 1 1;
    display: flex;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/App.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,SAAO;IACP,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["main.App {\n    width: 1250px;\n    height: 100%;\n    width: 100%;\n    padding: 2rem 0 0 0;\n    flex: 1;\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App_App__BPIIb`
};
export default ___CSS_LOADER_EXPORT___;
