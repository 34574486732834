// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_wellcome__U2VJ9 {
    display: flex;
    text-align: center;
    color: white;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.Home_wellcome__U2VJ9 h1 {
    display: flex;
    flex-direction: column;
    font-size: 40px;
    font-weight: 900;
}

.Home_wellcome__U2VJ9 h1 span {
    color: #f29559;
    letter-spacing: 0.8rem;
}

.Home_btn__bgKkW {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home_btn__bgKkW span {
    color: white;
    font-weight: 300;
    margin-bottom: 1rem;
}

.Home_btn__bgKkW button {
    padding: 1rem 1.2rem;
    background: transparent;
    border-radius: 30px;
    color: #f29559;
    border: 3px solid #f29559;
    font-weight: bold;
    width: 120px;
    cursor: pointer;
    transition: 0.3s;
}

.Home_btn__bgKkW button:hover {
    color: white;
    background-color: #f29559;
}
`, "",{"version":3,"sources":["webpack://./src/pages/css/Home.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".wellcome {\n    display: flex;\n    text-align: center;\n    color: white;\n    flex-direction: column;\n    height: 100%;\n    justify-content: center;\n}\n\n.wellcome h1 {\n    display: flex;\n    flex-direction: column;\n    font-size: 40px;\n    font-weight: 900;\n}\n\n.wellcome h1 span {\n    color: #f29559;\n    letter-spacing: 0.8rem;\n}\n\n.btn {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.btn span {\n    color: white;\n    font-weight: 300;\n    margin-bottom: 1rem;\n}\n\n.btn button {\n    padding: 1rem 1.2rem;\n    background: transparent;\n    border-radius: 30px;\n    color: #f29559;\n    border: 3px solid #f29559;\n    font-weight: bold;\n    width: 120px;\n    cursor: pointer;\n    transition: 0.3s;\n}\n\n.btn button:hover {\n    color: white;\n    background-color: #f29559;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wellcome": `Home_wellcome__U2VJ9`,
	"btn": `Home_btn__bgKkW`
};
export default ___CSS_LOADER_EXPORT___;
