// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: #f29559;
    padding: 2rem 0 0.6rem 0;
    text-align: center;
    margin-top: 10vh;
}

footer ul {
    display: flex;
    justify-content: space-evenly;
    width: 200px;
    gap: 1.4rem;
    margin-bottom: 2rem;
}

footer ul img {
    width: 24px;
}
footer span {
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-weight: 100;
}

footer span {
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/css/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;AACA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["footer {\n    width: 100%;\n    height: auto;\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n    background-color: #f29559;\n    padding: 2rem 0 0.6rem 0;\n    text-align: center;\n    margin-top: 10vh;\n}\n\nfooter ul {\n    display: flex;\n    justify-content: space-evenly;\n    width: 200px;\n    gap: 1.4rem;\n    margin-bottom: 2rem;\n}\n\nfooter ul img {\n    width: 24px;\n}\nfooter span {\n    color: white;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: end;\n    font-weight: 100;\n}\n\nfooter span {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
