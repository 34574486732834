// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__H\\+ozg {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1250px;
    flex: 1 1;
}

.Container_start__5jjqK {
    align-items: start;
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/css/Container.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,SAAO;AACX;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    max-width: 1250px;\n    flex: 1;\n}\n\n.start {\n    align-items: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__H+ozg`,
	"start": `Container_start__5jjqK`
};
export default ___CSS_LOADER_EXPORT___;
