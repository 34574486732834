// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1.Title_title__PbVL- {
    color: var(--color-porcelana);
    width: 100%;
    border-bottom: 3px solid var(--color-porcelana);
    margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/Title.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;IACX,+CAA+C;IAC/C,mBAAmB;AACvB","sourcesContent":["h1.title {\n    color: var(--color-porcelana);\n    width: 100%;\n    border-bottom: 3px solid var(--color-porcelana);\n    margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Title_title__PbVL-`
};
export default ___CSS_LOADER_EXPORT___;
