// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_form_input__me0It input {
    background: transparent;
    padding: 0.7rem 1.5rem;
    border: 1px solid var(--color-tangerina);
    border-radius: 30px;
    color: var(--color-tangerina);
    width: 100%;
}

.Input_form_input__me0It input::placeholder {
    color: var(--color-tangerina);
}

.Input_form_input__me0It input:focus {
    outline: none;
}

.Input_form_input__me0It input::placeholder:focus {
    border-bottom: 1px solid white;
    font-weight: 100;
}

.Input_form_input__me0It {
    width: 100%;
}

.Input_form_input__me0It input[type="number"]::-webkit-inner-spin-button,
.Input_form_input__me0It input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
}

.Input_inputSheet__ddLi\\+ {
    display: flex;
    flex-direction: column-reverse;
    padding: 0.4rem 1.2rem;
    gap: 1rem;
}

.Input_inputSheet__ddLi\\+ label {
    margin: 0 0.7rem;
    font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/css/Input.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,wCAAwC;IACxC,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".form_input input {\n    background: transparent;\n    padding: 0.7rem 1.5rem;\n    border: 1px solid var(--color-tangerina);\n    border-radius: 30px;\n    color: var(--color-tangerina);\n    width: 100%;\n}\n\n.form_input input::placeholder {\n    color: var(--color-tangerina);\n}\n\n.form_input input:focus {\n    outline: none;\n}\n\n.form_input input::placeholder:focus {\n    border-bottom: 1px solid white;\n    font-weight: 100;\n}\n\n.form_input {\n    width: 100%;\n}\n\n.form_input input[type=\"number\"]::-webkit-inner-spin-button,\n.form_input input[type=\"number\"]::-webkit-outer-spin-button {\n    appearance: none;\n}\n\n.inputSheet {\n    display: flex;\n    flex-direction: column-reverse;\n    padding: 0.4rem 1.2rem;\n    gap: 1rem;\n}\n\n.inputSheet label {\n    margin: 0 0.7rem;\n    font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_input": `Input_form_input__me0It`,
	"inputSheet": `Input_inputSheet__ddLi+`
};
export default ___CSS_LOADER_EXPORT___;
