// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    min-height: 100%;
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
}

#root {
    background-color: var(--color-azul-profundo);
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    min-width: 150px !important;
    --color-tangerina: #f29559;
    --color-azul-profundo: #202c39;
    --color-porcelana: #f2d492;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;;IAEI,YAAY;IACZ,gBAAgB;IAChB,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,4CAA4C;IAC5C,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,2BAA2B;IAC3B,0BAA0B;IAC1B,8BAA8B;IAC9B,0BAA0B;AAC9B","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nhtml,\nbody {\n    height: 100%;\n    min-height: 100%;\n    font-family: \"Poppins\", sans-serif;\n    overflow-x: hidden;\n}\n\n#root {\n    background-color: var(--color-azul-profundo);\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    min-height: 100%;\n    min-width: 150px !important;\n    --color-tangerina: #f29559;\n    --color-azul-profundo: #202c39;\n    --color-porcelana: #f2d492;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
