// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    width: 100vw;
    background-color: transparent;
}

nav a {
    text-decoration: none;
    color: #f2d492;
    margin: 0 2rem;
}

nav ul {
    color: #f2d492;
    font-weight: bold;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

li {
    list-style: none;
}

nav img {
    margin: 0 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/css/Header.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["header {\n    width: 100vw;\n    background-color: transparent;\n}\n\nnav a {\n    text-decoration: none;\n    color: #f2d492;\n    margin: 0 2rem;\n}\n\nnav ul {\n    color: #f2d492;\n    font-weight: bold;\n    display: flex;\n    gap: 1rem;\n    justify-content: center;\n    align-items: center;\n}\n\nli {\n    list-style: none;\n}\n\nnav img {\n    margin: 0 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
