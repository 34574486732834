import Container from "../components/layouts/Container";
import SheetCard from "../components/layouts/SheetCard";

function Fichas() {
    return (
        <Container>
            <SheetCard />
        </Container>
    );
}

export default Fichas;
