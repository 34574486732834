// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Skills_expertises__jrVYc {
    display: grid;
    grid-template-columns: repeat(3, auto);
    width: 100%;
}

.Skills_expertises__jrVYc li span {
    color: var(--color-tangerina);
    font-weight: bold;
}

.Skills_expertises__jrVYc li span::after {
    content: ": ";
}

.Skills_expertises__jrVYc li input {
    background: transparent;
    border: none;
    border-bottom: 2px solid var(--color-tangerina);
    text-align: center;
    color: var(--color-tangerina);
    width: 3rem;
    font-weight: bold;
}

.Skills_expertises__jrVYc input[type="number"]::-webkit-inner-spin-button,
.Skills_expertises__jrVYc input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
}

.Skills_expertises__jrVYc li {
    margin: 1rem 2rem;
    text-align: end;
}

.Skills_expertisesContainer__kWXZJ h2 {
    color: var(--color-tangerina);
    margin: 2rem 0;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/sheet/css/Skills.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sCAAsC;IACtC,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,+CAA+C;IAC/C,kBAAkB;IAClB,6BAA6B;IAC7B,WAAW;IACX,iBAAiB;AACrB;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".expertises {\n    display: grid;\n    grid-template-columns: repeat(3, auto);\n    width: 100%;\n}\n\n.expertises li span {\n    color: var(--color-tangerina);\n    font-weight: bold;\n}\n\n.expertises li span::after {\n    content: \": \";\n}\n\n.expertises li input {\n    background: transparent;\n    border: none;\n    border-bottom: 2px solid var(--color-tangerina);\n    text-align: center;\n    color: var(--color-tangerina);\n    width: 3rem;\n    font-weight: bold;\n}\n\n.expertises input[type=\"number\"]::-webkit-inner-spin-button,\n.expertises input[type=\"number\"]::-webkit-outer-spin-button {\n    appearance: none;\n}\n\n.expertises li {\n    margin: 1rem 2rem;\n    text-align: end;\n}\n\n.expertisesContainer h2 {\n    color: var(--color-tangerina);\n    margin: 2rem 0;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expertises": `Skills_expertises__jrVYc`,
	"expertisesContainer": `Skills_expertisesContainer__kWXZJ`
};
export default ___CSS_LOADER_EXPORT___;
