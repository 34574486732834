// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubmitButton_btnSubmit__WosrP {
    margin: 1rem;
}

.SubmitButton_btnSubmit__WosrP button {
    padding: 1rem 1.2rem;
    background: transparent;
    border-radius: 30px;
    color: #f29559;
    border: 3px solid #f29559;
    font-weight: bold;
    width: 120px;
    cursor: pointer;
    transition: 0.3s;
}

.SubmitButton_btnSubmit__WosrP button:hover {
    color: white;
    background-color: #f29559;
}

.SubmitButton_minimally__yMEIo button {
    border: none;
    font-size: 1.2rem;
    font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/css/SubmitButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".btnSubmit {\n    margin: 1rem;\n}\n\n.btnSubmit button {\n    padding: 1rem 1.2rem;\n    background: transparent;\n    border-radius: 30px;\n    color: #f29559;\n    border: 3px solid #f29559;\n    font-weight: bold;\n    width: 120px;\n    cursor: pointer;\n    transition: 0.3s;\n}\n\n.btnSubmit button:hover {\n    color: white;\n    background-color: #f29559;\n}\n\n.minimally button {\n    border: none;\n    font-size: 1.2rem;\n    font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnSubmit": `SubmitButton_btnSubmit__WosrP`,
	"minimally": `SubmitButton_minimally__yMEIo`
};
export default ___CSS_LOADER_EXPORT___;
